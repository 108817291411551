// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


html {
    overflow-x: hidden;
    max-width: 100%
}

body {
    position: relative;
    padding-bottom: 100px;
    color: #333333;
    font-family: "industry";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    max-width: 100%;
    background-color: white;
}

h1 {
    font-size: 44px;
    font-weight: 600;
    line-height: 1.3;
}

h3 {
    font-size: 18px;
    font-family: "Industry";
    color: #333333;
    line-height: 3.111;
    text-align: left;
    font-weight: bold;
}

p {
    font-size: 18px;
    line-height: 1.6;
}

.small-p {
    font-size: 16px;
}

.subheadline{
    font-size: 16px;
    color: #2fb335;
    font-weight: 800;
    text-transform: uppercase;
}

.button {
    background-color: #2fb335;
    text-decoration: none;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px 30px;
    border-radius: 35px;
    box-shadow: none!important;
    //-webkit-transition: background-color 1s, box-shadow 1s; /* Safari */
    //transition: background-color 1s, box-shadow 1s;
    display: block;
    border: none;
    text-align: center;
}

.button:hover, .button:visited {
    background-color: #45CA4B;
    box-shadow: none!important;
    text-decoration: none;
    color: #ffffff;
    text-decoration: none;
}

.button:focus, .button:active {
    outline: 0!important;
    text-decoration: none;
}

/* CUSTOMIZE THE NAVBAR
-------------------------------------------------- */

/* Special class on .container surrounding .navbar, used for positioning it into place. */
.navbar {
    border-radius: 0;
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
}

.navbar-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 20;
}

/* Flip around the padding for proper display in narrow viewports */
.navbar-wrapper > .container {
    padding-right: 0;
    padding-left: 0;
}

.navbar-wrapper .navbar {
    padding-right: 15px;
    padding-left: 15px;
}

.navbar-wrapper .navbar .container {
    width: auto;
}

.main-navbar > ul > li > a {
    font-size: 18px;
    font-weight: 500;
    padding: 30px 20px 40px 20px;
}

.logo {
    width: 190px;
    height: auto;
}

.navbar-inverse .navbar-nav > .active > a {
    border-bottom: 2px solid #2fb335;
    background-color: transparent;
}

.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover {
    color: #fff;
    background-color: transparent!important;
}


/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
    height: 900px;
    margin-bottom: 60px;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
    z-index: 10;
    text-shadow: none;
    top: 300px;
    left: 15px!important;
    right: 15px!important;
}

/* Declare heights because of positioning of img element */
.carousel .item {
    height: 900px;
    background-size: cover;
    background-position: center;

    &.-about {
        background-image: url('../images/HomeHeaderImage.jpg');
    }

    &.-offices {
        background-image: url('../images/OfficesHeaderImage.jpg');

    }

    &.-events {
        background-image: url('../images/EventsHeaderImage.jpg');
    }
}

.carousel-inner {
    height: 900px;
}

.green-line {
    position: absolute;
    height: 400px;
    width: 500px;
    border-left: 1px solid #2fb335;
    z-index: 11;
    top: 600px;
    left: 50%;
    z-index: 998;
}

.carousel-inner > .item > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
}

.carousel-caption > .hero {
    font-size: 70px;
    font-weight: 600;
}

.carousel-caption > .hero-subhl {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 40px;
}

.main-photo {
    min-height: 900px;
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.about {
    margin-top: 120px;
}

.marketing .col-lg-4 {
    margin-bottom: 20px;
    text-align: center;
}

.marketing h2 {
    font-size: 34px;
    font-weight: 400;
    line-height: 2;
    color: #333333;
    white-space: nowrap;
}

.marketing .col-lg-4 p {
    margin-right: 10px;
    margin-left: 10px;
}
.headline-divider {
    text-align: center;
    padding-top: 120px;
}

.headline-divider h1 {
    line-height: 1.3;
}

/* Featurettes
------------------------- */
.sign-about {
    position: absolute;
    width: auto;
    left: 50%;
    z-index: -5;
}

.sign {
    position: relative;
    left: -50%;
    top: 120px;
    font-size: 224px;
    font-weight: 800;
    color: #f5f5f5;
    white-space: pre;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.sign2 {
    position: relative;
    left: -50%;
    top: 150px;
    font-size: 224px;
    font-weight: 800;
    color: #f5f5f5;
    white-space: pre;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.collage-wrapper {
    position: relative;
}

.collage-img {
    box-shadow: 0 20px 26px 0 rgba(0, 0, 0, 0.17);
    display: block;
    z-index: auto;
    top: 0;
    left: 0;
}

.collage-left{
    position: absolute;
    top: 50px;
}

.collage-right{
    position: absolute;
    top: 122px;
    left: 258px;
}

.collage-center {
    position: absolute;
    left: 144px;
}

.featurette-divider {
    margin: 80px 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
    font-weight: 300;
    line-height: 1;
    letter-spacing: -1px;
}

.feature-heading h2 {
    font-weight: 400;
    line-height: 1;
    letter-spacing: -1px;
}

/* FORM
-------------------------------------------------- */


.input-label{
    background-color: #ffffff;
}

.has-error .input-group-addon {
    background-color: white;
}

.help-block {
    color: #a94442;
}

.input-group input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-left: 0;
    height: 45px;
}

.form-control {
    font-size: 18px;
}

.input-group-addon {
    font-size: 18px;
    font-weight: 500;
}

.-first {
    padding-right: 31px;
}

.text-area {
    height: 145px;
}

.row .button {
    box-shadow: 0 10px 46px 0 rgba(0, 0, 0, 0.25);
}

.text-area {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    resize: none;
    border-left: none;
    height: 120px;
    padding: 12px;
}

.textarea-label {
    vertical-align: top;
    padding-top: 14px;
}

.has-error .form-control:focus {
    box-shadow: none;
}

.contact-heading h2 {
    margin-top: 75px;
}

.map {
    width: 100%;
}

/* SIDR STYLES
-------------------------------------------------- */
.sidr {
    box-shadow: none;
    background-color: #000;
    display: none;
}

.sidr ul, .sidr ul li {
    border-top: 0;
    border-bottom: 0;
    line-height: 48px!important;
}

.sidr .active > a {
    background-color: #2fb335;
    box-shadow: none;
}

.sidr ul li:hover > a,
.sidr ul li:hover > span,
.sidr ul li.active > a,
.sidr ul li.active > span,
.sidr ul li.sidr-class-active > a,
.sidr ul li.sidr-class-active > span {
    box-shadow: none;
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 768px) {

    .navbar-wrapper {
        margin-top: 20px;
    }

    .navbar-wrapper .container {
        padding-right: 15px;
        padding-left: 15px;
    }

    .navbar-wrapper .navbar {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar-wrapper .navbar {
        border-radius: 0;
    }

    .carousel-caption p {
        margin-bottom: 20px;
        font-size: 21px;
        line-height: 1.4;
    }
}

@media (min-width: 992px) {
    .featurette-heading {
        margin-top: 10px;
    }

    .feature-heading {
        margin-top: 110px;
    }

    .contact-heading {
        margin-top: 75px;
    }

    //.subheadline {
    //    margin-top: 120px;
    //}
}

@media (max-width: 768px) {
    .navbar-header > button {
        margin-top: 21px;
        padding-bottom: 22px;
        border: none;
        background-color: transparent;
    }

    .logo {
        width: 140px;
        height: auto;
    }

    .navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
        background-color: transparent;
        border: none;
    }

    #myCarousel {
        margin-bottom: 20px;
    }

    .about {
        margin-top: 30px;
    }

    .navbar-toggle.collapsed{
        background-color: transparent;
        border: none;
        padding-bottom: 12px;
        margin-top: 14px;
        margin-bottom: 13px;
    }

    .navbar-nav {
        float: left !important;
        width: 100%;
    }

    #navbar > ul > .active > a {
        color: black;
    }

    #navbar.navbar-collapse {
        margin-left: -45px;
        width: 100vw;
        text-align: left;
        padding-left: 50px;
        padding-right: 0;
    }

    .carousel-caption{
        top: 200px;
    }

    .carousel-caption > .hero {
        font-size: 50px;
    }

    .green-line {
        top: 530px;
    }

    .sign, .sign2 {
        display: none;
    }

    .collage-wrapper {
        max-width: 100%;
        min-height: 500px;
    }

    .collage-center {
        left: 64px;
    }

    .collage-right {
        top: 122px;
        left: 136px;
    }

    .headline-divider {
        padding-top: 50px;
        text-align: left;
    }

    .about .subheadline {
        //margin-top: 70px;
    }

    .featurette p {
        margin-bottom: 30px;
    }
}

.green-text {
    color: #2fb335;
    font-weight: bold;

    &.-big {
        font-size: 18px;
    }

    &:hover, &:active, &:focus {
        color: #2fb335;
    }
}

.gray-text {
    color: #AAAAAA;
    font-weight: bold;

    &.-big {
        font-size: 18px;
    }
}

.margin-30 {
    margin-bottom: 30px;
}

.margin-50 {
    margin-bottom: 50px;
}

.margin-100 {
    margin-bottom: 100px;
}

.form-control, .form-control:focus {
    outline: 0!important;
    outline-style:none;
    box-shadow:none;
    border-color: #ccd0d2;
}

.manager-contact {
    font-size: 18px;
}

.layout-img {
    width: 70%;
}

.download-icon {
    font-size: 14px;
    float: left;
    padding-top: 5px;
}


.logo-col {
    text-align: center;

    img.company-logo {
        width: 100%;
        max-width: 150px;
        display: inline-block;
    }
}


.facility-icon {
    display: block;
    float: left;

    &.-restaurant {
        background-image: url('../images/restaurant-icon@2x.png');
        background-size: 30px 50px;
        width: 30px;
        height: 50px;
        margin-top: 45px;

    }

    &.-conference {
        background-image: url('../images/conference-icon@2x.png');
        background-size: 44px 50px;
        width: 44px;
        height: 50px;
        margin-top: 40px;
    }

    &.-offices {
        background-image: url('../images/offices-icon@2x.png');
        background-size: 46px 50px;
        width: 46px;
        height: 50px;
        margin-top: 40px;
    }
}

.icon-paragraph.-restaurant {
    //margin-left: 60px;
}

.icon-paragraph.-conference {
    //margin-left: 70px;
}

.list {
    list-style-type: none;
    padding-left: 20px;
    font-size: 16px;

    & > li {
        padding-right: 35px;
    }

    li:before {
        content: '\2014';
        position: absolute;
        margin-left: -20px;
    }
}

.link {
    color: #2fb335;
    text-decoration: underline;

    &:hover {
        color: #2fb335;
    }
}

.-max-width {
    max-width: 800px;
    display: inline-block;
}

.layout-col {
    .layout-image {
        width: 282px;
        display: block;
        margin-right: 0;
        margin-left: 0;
    }

    .small-p {
        min-height: 70px;
    }

    &.-clear-this {
        clear: both;
    }

    @media (max-width: 770px) {
        & {
            text-align: center;

            h3 {
                text-align: center;
            }

            .layout-image {
                margin-left: auto;
                margin-right: auto;
            }

        }

    }

    @media (max-width: 991px) {
        &.-clear-this {
            clear: none;
        }
    }
}

.special-h {
    font-size: 46px;

}

.special-p {
    font-size: 26px;

}
